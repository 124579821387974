/**
 * Animations.
 *
 * Instantiate WowJS effects.
 */

const animations = () => {
  const wow = new WOW(); // eslint-disable-line no-undef

  wow.init();
};

animations();

const menu = () => {
  const toggle = document.querySelector(".hamburger");
  const toggleMenu = () => {
    toggle.classList.toggle("is-active");

    const navigation = document.querySelector(".navigation");

    navigation.classList.toggle("navigation--open");

    if (navigation.classList.contains("navigation--open")) {
      bodyScrollLock.disableBodyScroll(navigation); // eslint-disable-line no-undef
    } else {
      bodyScrollLock.enableBodyScroll(navigation); // eslint-disable-line no-undef
    }
  };

  toggle.addEventListener("click", toggleMenu);
};

menu();

const testimonials = () => {
  // eslint-disable-next-line no-undef
  $(".testimonial__slider").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    autoplay: true
  });
};

testimonials();

const slider = () => {
  // eslint-disable-next-line no-undef
  $(".introduction__slider").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: true
  });
};

slider();
